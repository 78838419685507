import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M0 6400 l0 -6400 6400 0 6400 0 0 6400 0 6400 -6400 0 -6400 0 0
-6400z m3640 2205 c47 -9 105 -20 130 -25 42 -8 166 -45 290 -86 86 -29 356
-166 450 -229 135 -91 246 -185 370 -312 174 -178 285 -333 394 -549 63 -126
79 -165 120 -289 51 -154 53 -160 71 -255 9 -47 21 -101 26 -121 13 -52 12
-625 -2 -691 -12 -57 -45 -202 -59 -258 -22 -87 -95 -272 -153 -388 -187 -373
-459 -674 -813 -898 -88 -55 -342 -184 -363 -184 -6 0 -19 -4 -29 -9 -54 -29
-263 -89 -412 -117 -133 -25 -543 -31 -690 -10 -174 25 -413 89 -519 137 -24
11 -64 29 -90 39 -58 23 -255 128 -286 152 -5 5 -47 34 -93 66 -322 227 -605
572 -749 917 -14 33 -31 74 -38 90 -11 26 -57 166 -84 260 -5 17 -17 73 -26
125 -9 52 -21 111 -27 130 -12 46 -4 577 10 656 51 279 156 572 269 752 18 29
33 55 33 57 0 14 129 192 203 280 146 174 314 320 507 441 105 66 306 168 390
199 87 31 186 62 255 79 39 10 86 21 105 26 31 8 131 22 230 34 86 10 498 -4
580 -19z m3019 -1399 c19 -20 21 -27 12 -45 -12 -21 -51 -30 -51 -10 0 17 -71
41 -105 35 -41 -8 -63 -45 -47 -80 8 -18 30 -30 92 -51 110 -37 127 -57 121
-145 -6 -81 -134 -127 -240 -84 -44 17 -56 36 -41 65 14 25 28 24 57 -2 14
-14 40 -24 72 -27 43 -4 53 -1 75 21 14 15 26 31 26 37 0 23 -49 58 -105 74
-76 23 -102 41 -115 81 -23 70 12 138 82 156 56 14 140 2 167 -25z m384 23
c59 -16 87 -78 42 -96 -10 -3 -27 5 -43 21 -37 34 -81 42 -123 22 -52 -25 -73
-61 -77 -132 -7 -109 47 -184 133 -184 64 0 75 12 75 86 l0 65 33 -3 c32 -3
32 -3 35 -65 4 -74 -8 -100 -53 -119 -71 -29 -179 -12 -234 39 -60 55 -80 180
-42 263 40 89 148 133 254 103z m415 -18 c76 -39 104 -90 104 -189 -1 -81 -30
-144 -84 -179 -77 -51 -265 -37 -262 20 0 9 1 95 2 191 2 157 4 175 20 180 37
11 183 -4 220 -23z m1439 3 c31 -20 30 -58 -2 -72 -21 -10 -26 -9 -35 8 -5 10
-26 24 -45 30 -68 23 -125 -12 -105 -64 8 -23 23 -31 151 -76 99 -35 88 -181
-16 -217 -58 -21 -115 -20 -162 1 -48 21 -59 47 -32 72 18 16 20 16 42 -4 29
-27 97 -38 137 -22 26 11 30 18 30 50 0 37 -14 51 -65 64 -98 24 -103 27 -129
64 -53 77 -23 152 73 183 36 12 131 1 158 -17z m478 -189 l0 -210 -32 -3 c-28
-3 -33 0 -33 18 0 20 0 20 -37 2 -57 -29 -109 -34 -154 -15 -66 26 -73 51 -74
245 l0 168 32 0 c21 0 36 -6 41 -17 6 -10 7 -72 3 -143 -8 -144 -4 -167 30
-192 23 -17 31 -18 75 -7 82 19 86 29 84 204 -2 82 -2 154 -1 158 0 5 16 7 34
5 l32 -3 0 -210z m342 203 c33 -15 60 -42 69 -70 9 -31 -5 -87 -26 -100 -17
-11 -17 -13 11 -39 25 -23 29 -34 29 -81 0 -60 -16 -90 -60 -113 -35 -18 -138
-19 -195 -1 l-40 12 2 199 c2 110 3 201 3 203 0 8 184 0 207 -10z m664 -17
c26 -31 24 -67 -3 -82 -19 -10 -25 -8 -43 15 -44 56 -123 50 -170 -12 -16 -22
-20 -43 -20 -107 0 -71 3 -85 25 -112 44 -56 134 -68 175 -23 23 25 36 25 57
0 17 -18 16 -21 -5 -45 -40 -46 -166 -52 -246 -11 -16 9 -43 36 -60 62 -28 42
-31 55 -31 121 0 154 73 226 223 221 69 -3 81 -6 98 -27z m396 18 c40 -15 82
-57 104 -104 25 -54 25 -150 0 -207 -35 -81 -86 -110 -194 -109 -86 0 -138 27
-175 91 -23 38 -27 56 -26 120 0 97 22 144 88 188 42 28 57 32 111 32 35 0 76
-5 92 -11z m294 -2 c25 -19 72 -115 104 -215 17 -50 32 -92 35 -92 3 0 16 35
29 78 31 96 89 210 118 229 12 8 34 13 48 11 l27 -3 -1 -210 0 -210 -33 0 -33
0 -5 153 -5 154 -30 -79 c-16 -43 -30 -80 -30 -83 -1 -3 -5 -12 -8 -20 -4 -8
-15 -40 -25 -70 -17 -50 -22 -55 -48 -55 -24 0 -32 7 -51 45 -12 25 -30 67
-39 95 -9 27 -28 72 -41 100 l-23 50 3 -147 3 -148 -33 0 -33 0 0 173 c0 94
-4 187 -10 206 -5 19 -7 38 -4 42 8 13 67 10 85 -4z m-5135 -14 c20 -21 74
-135 99 -213 33 -102 34 -102 66 -4 16 49 46 121 67 159 37 67 40 70 78 73
l39 3 -2 -208 -1 -208 -29 0 -28 0 -5 155 -5 155 -23 -49 c-12 -27 -38 -98
-58 -158 -35 -105 -37 -109 -63 -106 -30 3 -36 15 -102 186 l-44 113 -3 -150
c-2 -135 -4 -151 -20 -151 -38 0 -40 11 -44 218 l-3 202 32 0 c17 0 39 -8 49
-17z m1893 -1 c6 -10 27 -63 47 -118 20 -54 54 -136 75 -182 22 -46 37 -88 34
-93 -4 -5 -17 -9 -31 -9 -21 0 -30 10 -55 65 l-29 65 -78 0 -78 0 -26 -67
c-14 -37 -30 -69 -35 -70 -5 -2 -17 3 -28 11 -19 13 -18 16 17 97 20 46 55
137 79 202 41 110 46 117 71 117 16 0 31 -7 37 -18z m441 5 c0 -25 -25 -37
-78 -37 l-54 0 3 -180 c2 -98 0 -182 -3 -185 -3 -3 -17 -3 -32 1 l-26 6 0 179
0 179 -65 0 c-56 0 -65 2 -65 18 0 10 3 22 7 25 3 4 75 7 160 7 119 0 153 -3
153 -13z m202 -62 c16 -38 38 -97 50 -130 11 -33 35 -93 54 -132 32 -70 32
-73 13 -83 -25 -14 -55 2 -64 32 -3 13 -13 40 -22 61 l-16 38 -81 -3 -80 -3
-22 -63 c-26 -75 -26 -76 -54 -67 -28 9 -24 33 24 145 21 47 50 123 67 170 16
47 31 91 34 98 3 8 17 12 37 10 28 -3 34 -9 60 -73z m1506 -260 c39 -49 2
-106 -55 -84 -47 18 -57 55 -23 89 27 27 54 25 78 -5z m-1468 -65 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m868 -262 c3
-29 -5 -33 -55 -29 -20 1 -35 -11 -40 -34 -10 -47 -13 -57 -19 -67 -5 -8 -2
-9 10 -5 45 17 64 18 80 2 29 -30 13 -139 -27 -184 -35 -37 -84 -55 -118 -41
-40 17 -41 54 -8 185 5 22 11 51 13 65 10 81 71 143 136 137 19 -2 26 -8 28
-29z m-2430 0 c-2 -19 -10 -23 -41 -24 -20 -1 -40 -4 -43 -6 -8 -8 -34 -111
-29 -116 2 -2 12 1 23 7 30 16 69 13 76 -5 11 -29 6 -96 -10 -130 -34 -71
-120 -110 -154 -69 -17 20 -7 111 22 220 27 99 65 145 123 145 32 0 36 -3 33
-22z m982 -4 c0 -25 -2 -26 -35 -21 -22 4 -40 1 -47 -7 -16 -15 -74 -263 -64
-273 3 -4 12 -1 18 5 16 16 28 15 28 0 0 -18 -62 -58 -89 -58 -18 0 -22 4 -18
20 6 25 6 25 -24 0 -15 -14 -34 -20 -54 -18 -25 3 -31 8 -33 33 -5 42 13 95
48 143 24 33 38 43 69 48 34 5 40 11 50 44 21 70 64 108 124 109 23 1 27 -3
27 -25z m1764 -13 c10 -16 -5 -41 -24 -41 -23 0 -32 13 -24 34 7 17 39 22 48
7z m117 -89 c-8 -28 -7 -32 10 -32 10 0 19 -7 19 -15 0 -8 -3 -14 -7 -14 -33
5 -42 -3 -49 -46 -3 -25 -10 -48 -15 -51 -12 -7 -12 -44 0 -44 5 0 14 7 21 15
20 24 48 19 46 -9 0 -13 -4 -22 -8 -20 -4 2 -23 -7 -44 -21 -42 -29 -57 -31
-74 -10 -9 11 -9 31 4 93 20 97 20 103 1 96 -9 -4 -15 0 -15 10 0 9 8 16 21
16 13 0 23 10 30 29 10 29 48 55 61 42 4 -4 4 -21 -1 -39z m-2381 3 c-7 -33
-6 -35 17 -35 15 0 23 -5 20 -12 -2 -7 -14 -13 -27 -13 -20 0 -26 -9 -42 -60
-18 -62 -23 -95 -12 -95 3 0 19 9 36 21 34 24 49 18 24 -9 -25 -29 -81 -56
-100 -49 -21 8 -20 37 3 120 15 50 17 69 8 72 -48 14 -48 11 6 29 9 3 17 14
17 25 0 20 24 40 46 41 8 0 9 -11 4 -35z m774 0 c-5 -31 -4 -35 15 -35 28 0
27 -18 -1 -22 -19 -2 -27 -16 -46 -72 -12 -39 -18 -74 -14 -78 5 -5 21 1 36
13 18 14 31 18 37 12 14 -14 -67 -73 -98 -73 -20 0 -23 5 -23 38 0 20 7 62 15
92 19 70 19 70 -6 70 -11 0 -17 5 -14 10 3 6 15 10 25 10 13 0 22 9 26 25 6
23 28 44 47 45 4 0 5 -16 1 -35z m-1570 -13 c7 -12 -5 -25 -39 -42 -40 -21
-71 -70 -86 -137 -12 -53 -31 -75 -49 -57 -5 5 -1 44 10 89 24 100 24 102 2
82 -22 -20 -32 -21 -32 -4 0 17 62 57 89 57 19 0 21 -4 15 -22 -6 -19 -2 -18
24 10 29 31 55 41 66 24z m2517 -23 c20 -32 -6 -69 -72 -107 -47 -26 -62 -53
-38 -67 6 -4 27 4 46 18 36 26 53 27 53 4 0 -19 -84 -67 -117 -67 -35 0 -43
13 -43 73 0 49 30 112 68 143 30 25 88 26 103 3z m404 -7 c3 -8 -1 -22 -9 -30
-12 -13 -16 -12 -32 6 -13 16 -21 19 -31 10 -11 -9 -9 -19 12 -54 34 -57 33
-106 -1 -133 -50 -40 -124 -22 -124 29 0 25 4 30 25 30 14 0 26 -4 26 -10 1
-5 2 -15 3 -22 2 -20 24 -20 36 1 8 16 6 29 -10 60 -36 71 -18 127 43 133 41
4 54 0 62 -20z m109 -7 c0 -17 -8 -59 -17 -93 -15 -63 -15 -64 4 -46 26 23 37
9 17 -23 -20 -33 -61 -58 -82 -50 -20 8 -21 64 -3 110 8 18 17 45 21 61 6 26
6 27 -9 12 -20 -19 -35 -21 -35 -3 0 21 17 40 47 53 42 19 58 13 57 -21z m357
18 c6 -6 9 -23 7 -39 -2 -23 -15 -36 -60 -63 -40 -23 -58 -40 -58 -53 0 -17 4
-19 28 -13 15 3 35 14 45 23 16 14 19 14 27 2 17 -28 -65 -90 -119 -90 -31 0
-41 29 -32 93 5 35 18 71 36 99 16 24 29 45 28 48 -3 9 88 3 98 -7z m-4203 -5
c5 -7 18 -38 27 -68 33 -101 30 -99 47 -48 10 28 14 62 11 87 -5 37 -4 41 17
41 21 0 22 -3 18 -55 -6 -67 -61 -182 -118 -248 -61 -69 -156 -91 -148 -35 3
20 8 23 41 20 103 -8 144 103 86 236 -19 46 -21 47 -49 32 -34 -18 -33 9 2 30
38 23 52 25 66 8z m326 -10 c49 -70 -24 -208 -109 -208 -46 0 -65 22 -65 77 0
87 52 153 121 153 28 0 42 -6 53 -22z m138 -13 c-2 -16 -9 -53 -17 -82 -7 -28
-10 -54 -7 -57 2 -3 18 2 33 11 22 13 35 34 52 88 20 57 28 69 43 67 10 -2 20
-5 21 -6 1 -1 -9 -39 -23 -86 -13 -46 -24 -85 -24 -87 0 -9 21 -1 35 13 11 11
20 13 26 7 7 -7 0 -19 -24 -37 -45 -34 -75 -34 -79 0 l-3 26 -27 -21 c-14 -11
-37 -22 -50 -23 -30 -4 -34 27 -14 113 16 70 14 78 -9 59 -9 -7 -19 -10 -23
-6 -12 12 48 57 72 54 17 -2 20 -9 18 -33z m843 15 c10 -30 -17 -64 -70 -90
-53 -26 -65 -45 -42 -67 14 -14 19 -14 52 7 50 31 58 15 12 -26 -31 -29 -44
-35 -75 -32 -36 3 -37 4 -40 47 -7 92 54 181 123 181 25 0 35 -5 40 -20z m159
14 c20 -8 21 -44 1 -44 -9 0 -18 7 -21 15 -4 8 -12 15 -20 15 -20 0 -17 -44 6
-87 25 -47 25 -64 -1 -97 -40 -51 -144 -27 -123 29 9 23 38 14 42 -13 3 -17 9
-22 25 -20 30 4 33 37 8 84 -12 21 -21 49 -21 61 0 46 55 76 104 57z m781 -11
c-13 -34 -43 -158 -39 -162 2 -3 10 1 16 7 16 16 28 15 28 -2 0 -19 -52 -56
-79 -56 -14 0 -21 6 -21 20 0 20 0 20 -29 -1 -16 -11 -39 -19 -52 -17 -22 3
-24 8 -24 54 1 38 8 61 30 97 31 48 56 64 115 70 19 2 41 5 49 5 9 1 11 -4 6
-15z m400 0 c-11 -29 -43 -158 -40 -161 2 -2 12 2 24 9 36 23 37 -2 1 -32 -41
-35 -80 -39 -80 -9 0 20 0 20 -29 -1 -16 -11 -39 -19 -52 -17 -20 2 -24 9 -27
45 -2 25 4 55 13 73 24 47 84 98 119 103 72 9 79 8 71 -10z m295 10 c0 -3 -12
-30 -26 -58 -20 -42 -24 -61 -18 -91 l7 -38 34 39 c21 24 33 49 33 67 0 42 27
88 51 88 19 0 20 -4 15 -37 -15 -91 -18 -143 -9 -143 20 0 61 40 77 75 12 24
15 46 10 70 -6 32 -4 35 19 35 31 0 40 -34 21 -80 -8 -18 -11 -36 -8 -41 3 -5
0 -8 -8 -7 -7 2 -12 -5 -12 -14 1 -10 -2 -16 -6 -13 -5 3 -32 -15 -61 -40 -69
-59 -69 -59 -84 9 l-6 29 -27 -25 c-30 -28 -71 -52 -78 -45 -2 2 -9 29 -15 61
-11 60 1 126 23 126 6 0 9 2 6 5 -13 13 -58 -8 -58 -26 0 -11 -7 -19 -16 -19
-11 0 -14 8 -12 32 2 27 8 34 33 39 41 9 115 10 115 2z m1454 -180 c8 -19 -11
-53 -30 -53 -19 0 -28 24 -20 54 4 18 11 23 25 19 11 -3 22 -12 25 -20z m121
-12 c0 -32 -30 -51 -49 -31 -21 22 -3 62 26 58 17 -2 23 -10 23 -27z m128 17
c7 -20 -11 -58 -28 -58 -17 0 -35 38 -28 58 3 6 15 12 28 12 13 0 25 -6 28
-12z m622 -308 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7
-4 4 -10z m-225 -54 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6
0 10 -2 10 -4z m-4125 -26 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4
10 3 0 8 -4 11 -10z m2755 -24 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25
14 25 6z m-365 -56 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2
0 7 -4 10 -10z m2640 -31 c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14 5 0 13 -5 16
-11z m130 1 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4
16 -10z m-2178 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m520
-20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1403 -13 c3 -8 1
-15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m656 -6 c0 -11
-4 -18 -10 -14 -5 3 -7 12 -3 20 7 21 13 19 13 -6z m2510 17 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-1523 -78 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-867 2 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m2352 -12 c-7 -7 -12 -8 -12 -2 0
14 12 26 19 19 2 -3 -1 -11 -7 -17z m-114 -33 c-3 -3 -9 2 -12 12 -6 14 -5 15
5 6 7 -7 10 -15 7 -18z m-2404 -31 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14
7 11 14 -7z m1086 -4 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2
0 4 -4 4 -10z m940 -6 c0 -2 -7 -4 -15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8
-4 15 -8 15 -10z m-1865 -4 c-3 -5 -14 -10 -23 -9 -14 0 -13 2 3 9 27 11 27
11 20 0z m605 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11
-4 11 -10z m710 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m910 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11
6 0 10 -2 10 -4z m-2055 -164 c-8 -8 -25 10 -25 26 0 13 2 13 14 -4 8 -10 13
-21 11 -22z m125 8 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5
0 9 -4 9 -10z"/>
<path d="M1957 7522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2152 7520 c-20 -33 -15 -68 14 -87 24 -15 28 -15 52 0 16 9 28 26
30 42 7 53 -69 88 -96 45z"/>
<path d="M2357 7522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4152 7520 c-20 -33 -15 -68 14 -87 24 -15 28 -15 52 0 16 9 28 26
30 42 7 53 -69 88 -96 45z"/>
<path d="M4357 7522 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M4552 7520 c-30 -48 8 -111 58 -95 17 5 20 15 20 61 l0 54 -33 0
c-21 0 -36 -7 -45 -20z"/>
<path d="M3998 7513 c-19 -22 -38 -58 -38 -72 0 -19 39 -22 65 -5 27 18 34 61
13 82 -16 16 -22 15 -40 -5z"/>
<path d="M2540 7480 c0 -21 8 -35 26 -46 38 -26 48 -5 19 39 -31 45 -45 47
-45 7z"/>
<path d="M1957 7322 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2157 7322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2357 7322 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2557 7322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3957 7322 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M4157 7322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4357 7322 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M4557 7322 c-39 -42 0 -114 53 -97 18 6 21 13 18 58 -3 47 -5 52 -29
55 -15 2 -32 -5 -42 -16z"/>
<path d="M1956 7121 c-25 -27 -17 -72 15 -90 24 -13 29 -13 53 2 34 22 36 69
4 91 -30 21 -51 20 -72 -3z"/>
<path d="M2157 7122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 35 20 41 62
13 88 -26 24 -53 24 -74 1z"/>
<path d="M2357 7122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M2557 7122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3760 7110 c-26 -37 -23 -60 11 -79 23 -13 28 -12 52 5 31 23 35 63
9 87 -27 25 -48 21 -72 -13z"/>
<path d="M3957 7122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M4157 7122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4357 7122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M4557 7122 c-38 -42 -5 -111 48 -98 24 6 26 10 23 59 -3 47 -5 52
-29 55 -15 2 -32 -5 -42 -16z"/>
<path d="M2752 7118 c-21 -21 -14 -66 13 -84 49 -32 79 6 49 62 -19 35 -41 43
-62 22z"/>
<path d="M1957 6922 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2157 6922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2357 6922 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2557 6922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2757 6922 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M3757 6922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3957 6922 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M4157 6922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4357 6922 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M4557 6922 c-39 -42 0 -114 53 -97 18 6 21 13 18 58 -3 47 -5 52 -29
55 -15 2 -32 -5 -42 -16z"/>
<path d="M3622 6880 c-12 -20 -16 -50 -6 -50 13 0 34 30 34 49 0 26 -12 27
-28 1z"/>
<path d="M1957 6722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M2157 6722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2357 6722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M2557 6722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2756 6721 c-25 -27 -17 -72 15 -90 24 -13 29 -13 53 2 34 22 36 69
4 91 -30 21 -51 20 -72 -3z"/>
<path d="M2957 6722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 34 20 39 49
15 81 -21 30 -53 33 -76 8z"/>
<path d="M3556 6721 c-25 -27 -17 -72 15 -90 24 -13 29 -13 53 2 34 22 36 69
4 91 -30 21 -51 20 -72 -3z"/>
<path d="M3757 6722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3957 6722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M4157 6722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4356 6721 c-25 -27 -17 -72 15 -90 24 -13 29 -13 53 2 34 22 36 69
4 91 -30 21 -51 20 -72 -3z"/>
<path d="M4557 6722 c-39 -42 0 -114 53 -97 18 6 21 13 18 58 -3 47 -5 52 -29
55 -15 2 -32 -5 -42 -16z"/>
<path d="M1957 6522 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2157 6522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2357 6522 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2557 6522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2757 6522 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2957 6522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3557 6522 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M3757 6522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3957 6522 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M4157 6522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4357 6522 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M4557 6522 c-39 -42 0 -114 53 -97 18 6 21 13 18 58 -3 47 -5 52 -29
55 -15 2 -32 -5 -42 -16z"/>
<path d="M3398 6498 c-40 -62 -25 -98 26 -61 20 13 25 26 24 48 -4 37 -30 44
-50 13z"/>
<path d="M3140 6481 c0 -22 7 -35 26 -47 36 -24 48 -7 25 36 -25 47 -51 52
-51 11z"/>
<path d="M1956 6321 c-25 -27 -17 -72 15 -90 24 -13 29 -13 53 2 34 22 36 69
4 91 -30 21 -51 20 -72 -3z"/>
<path d="M2157 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 35 20 41 62
13 88 -26 24 -53 24 -74 1z"/>
<path d="M2357 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M2557 6322 c-25 -27 -21 -69 7 -87 21 -14 26 -14 55 2 22 13 31 25
31 44 0 51 -60 77 -93 41z"/>
<path d="M2757 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M2957 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 35 20 41 62
13 88 -26 24 -53 24 -74 1z"/>
<path d="M3157 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M3357 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3557 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M3757 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 35 20 41 62
13 88 -26 24 -53 24 -74 1z"/>
<path d="M3957 6322 c-20 -22 -21 -33 -5 -66 15 -34 41 -41 73 -20 32 21 34
66 3 88 -29 21 -51 20 -71 -2z"/>
<path d="M4157 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4357 6322 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M4557 6322 c-38 -42 -5 -111 48 -98 24 6 26 10 23 59 -3 47 -5 52
-29 55 -15 2 -32 -5 -42 -16z"/>
<path d="M1957 6122 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2157 6122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2357 6122 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2557 6122 c-26 -28 -22 -69 9 -88 34 -23 46 -8 42 52 -3 55 -22 68
-51 36z"/>
<path d="M2757 6122 c-23 -25 -21 -54 3 -80 25 -26 38 -27 68 -6 42 29 18 104
-35 104 -11 0 -27 -8 -36 -18z"/>
<path d="M2957 6122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3157 6122 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M3357 6122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3557 6122 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M3757 6122 c-35 -38 -8 -112 29 -81 23 19 45 66 38 83 -8 22 -47 20
-67 -2z"/>
<path d="M3960 6121 c-22 -42 -2 -101 33 -101 27 0 57 32 57 59 0 52 -67 83
-90 42z"/>
<path d="M4157 6122 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4357 6122 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M4557 6122 c-39 -42 0 -114 53 -97 18 6 21 13 18 58 -3 47 -5 52 -29
55 -15 2 -32 -5 -42 -16z"/>
<path d="M1957 5922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M2157 5922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2357 5922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M2557 5922 c-26 -28 -22 -69 9 -88 34 -23 46 -8 42 52 -3 55 -22 68
-51 36z"/>
<path d="M2957 5922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3157 5922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M3357 5922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3556 5921 c-25 -27 -17 -72 15 -90 24 -13 29 -13 53 2 34 22 36 69
4 91 -30 21 -51 20 -72 -3z"/>
<path d="M3960 5921 c-21 -40 -2 -101 31 -101 26 0 59 34 59 61 0 49 -68 79
-90 40z"/>
<path d="M4157 5922 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4356 5921 c-25 -27 -17 -72 15 -90 24 -13 29 -13 53 2 34 22 36 69
4 91 -30 21 -51 20 -72 -3z"/>
<path d="M4557 5922 c-39 -42 0 -114 53 -97 18 6 21 13 18 58 -3 47 -5 52 -29
55 -15 2 -32 -5 -42 -16z"/>
<path d="M1957 5722 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2157 5722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M2357 5722 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M2557 5722 c-26 -28 -22 -69 9 -88 34 -23 46 -8 42 52 -3 55 -22 68
-51 36z"/>
<path d="M2963 5724 c-5 -14 23 -80 39 -90 16 -10 48 22 48 47 0 47 -72 83
-87 43z"/>
<path d="M3157 5722 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M3357 5722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M3557 5722 c-21 -22 -22 -62 -3 -62 16 0 46 42 46 65 0 21 -23 19
-43 -3z"/>
<path d="M3959 5719 c-16 -30 -3 -86 22 -94 31 -10 69 20 69 54 0 53 -67 83
-91 40z"/>
<path d="M4157 5722 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4357 5722 c-26 -28 -21 -69 9 -89 24 -15 29 -15 53 -2 16 9 27 25
29 43 7 55 -56 87 -91 48z"/>
<path d="M4557 5722 c-39 -42 0 -114 53 -97 18 6 21 13 18 58 -3 47 -5 52 -29
55 -15 2 -32 -5 -42 -16z"/>
<path d="M1956 5521 c-25 -27 -17 -72 15 -90 24 -13 29 -13 53 2 34 22 36 69
4 91 -30 21 -51 20 -72 -3z"/>
<path d="M2157 5522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 35 20 41 62
13 88 -26 24 -53 24 -74 1z"/>
<path d="M2357 5522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M2557 5522 c-26 -28 -22 -69 9 -88 34 -23 46 -8 42 52 -3 55 -22 68
-51 36z"/>
<path d="M3157 5522 c-35 -38 -17 -72 38 -72 38 0 55 11 55 36 0 24 -33 54
-61 54 -9 0 -23 -8 -32 -18z"/>
<path d="M3357 5522 c-35 -38 -17 -72 38 -72 38 0 55 11 55 35 0 47 -62 72
-93 37z"/>
<path d="M3959 5513 c-13 -35 -2 -81 21 -88 30 -9 70 23 70 56 0 54 -73 80
-91 32z"/>
<path d="M4157 5522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 33 19 40 61
16 88 -22 24 -56 24 -77 1z"/>
<path d="M4357 5522 c-26 -28 -21 -69 9 -89 24 -15 28 -15 52 0 37 21 42 69
10 91 -29 21 -51 20 -71 -2z"/>
<path d="M4557 5522 c-38 -42 -5 -111 48 -98 24 6 26 10 23 59 -3 47 -5 52
-29 55 -15 2 -32 -5 -42 -16z"/>
<path d="M1957 5322 c-10 -10 -17 -33 -17 -51 l0 -32 53 3 c49 3 52 5 55 31 7
56 -56 88 -91 49z"/>
<path d="M2156 5321 c-10 -11 -16 -31 -14 -48 3 -27 6 -28 53 -28 47 0 50 1
53 28 4 34 -22 67 -53 67 -12 0 -30 -9 -39 -19z"/>
<path d="M2357 5322 c-10 -10 -17 -33 -17 -51 l0 -32 53 3 c49 3 52 5 55 31 7
56 -56 88 -91 49z"/>
<path d="M2556 5321 c-10 -11 -16 -31 -14 -48 2 -24 8 -28 36 -31 l33 -3 -3
48 c-3 54 -23 67 -52 34z"/>
<path d="M3965 5328 c-6 -16 -9 -47 -7 -71 2 -15 9 -18 45 -15 38 3 42 6 45
31 2 17 -4 37 -14 48 -19 21 -62 26 -69 7z"/>
<path d="M4156 5321 c-10 -11 -16 -31 -14 -48 3 -27 6 -28 53 -28 47 0 50 1
53 28 4 34 -22 67 -53 67 -12 0 -30 -9 -39 -19z"/>
<path d="M4357 5322 c-10 -10 -17 -33 -17 -51 l0 -32 53 3 c49 3 52 5 55 31 7
56 -56 88 -91 49z"/>
<path d="M4556 5321 c-10 -11 -16 -31 -14 -48 3 -25 7 -28 46 -31 l43 -3 -3
48 c-3 43 -6 48 -29 51 -15 2 -32 -5 -43 -17z"/>
<path d="M7281 7031 c-1 -157 -1 -159 21 -165 73 -19 137 1 166 53 28 49 30
140 4 191 -21 43 -89 80 -148 80 l-42 0 -1 -159z"/>
<path d="M9593 7184 c-20 -9 -17 -110 3 -118 20 -8 96 12 106 28 15 22 8 56
-15 71 -26 17 -76 27 -94 19z"/>
<path d="M9580 6941 c0 -73 5 -81 55 -81 67 0 109 55 81 108 -18 35 -33 42
-92 42 l-44 0 0 -69z"/>
<path d="M10656 7179 c-34 -10 -63 -35 -82 -71 -17 -33 -19 -138 -2 -138 6 0
9 -3 5 -6 -8 -9 16 -55 40 -76 11 -10 39 -21 64 -24 36 -5 50 -1 79 18 52 35
62 61 57 151 -3 65 -8 81 -28 103 -40 43 -85 57 -133 43z"/>
<path d="M7787 7134 c-4 -4 -7 -15 -7 -24 0 -9 -9 -35 -20 -58 -11 -22 -20
-46 -20 -52 0 -6 24 -10 56 -10 63 0 61 -6 20 95 -12 30 -25 52 -29 49z"/>
<path d="M8379 7065 l-26 -75 58 0 57 0 -25 68 c-14 37 -29 70 -32 74 -3 4
-18 -26 -32 -67z"/>
<path d="M9262 6366 c-32 -46 -37 -131 -8 -131 23 0 65 68 66 107 1 65 -23 75
-58 24z"/>
<path d="M6847 6386 c-29 -29 -51 -89 -45 -126 2 -19 9 -25 27 -25 43 0 87
120 58 159 -13 18 -15 18 -40 -8z"/>
<path d="M7702 6385 c-37 -31 -60 -101 -40 -122 12 -12 18 -11 40 7 22 16 58
93 58 121 0 19 -32 16 -58 -6z"/>
<path d="M9056 6378 c-9 -17 -15 -33 -13 -35 8 -7 57 39 57 53 0 26 -28 15
-44 -18z"/>
<path d="M9934 6384 c-8 -20 -13 -38 -11 -40 8 -8 47 38 47 56 0 32 -20 23
-36 -16z"/>
<path d="M6041 6394 c-25 -32 -36 -100 -22 -136 25 -65 90 -7 92 81 2 78 -31
104 -70 55z"/>
<path d="M7042 6407 c-6 -7 -15 -25 -21 -40 -8 -21 -8 -27 3 -27 18 0 56 43
56 63 0 20 -23 22 -38 4z"/>
<path d="M7943 6372 c-34 -31 -53 -100 -31 -113 28 -18 76 40 93 114 6 23 4
27 -13 27 -11 0 -33 -12 -49 -28z"/>
<path d="M8341 6372 c-32 -31 -50 -100 -30 -113 30 -18 72 29 87 99 9 38 8 42
-10 42 -11 0 -32 -12 -47 -28z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
